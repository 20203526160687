<template>
    <button class="btn btn-outline-primary" @click="showDialog()">
        <slot>
            <i class="bi bi-camera"></i>
            <span>{{ $t('Capture photo') }}</span>
        </slot>
    </button>
    <Teleport to="body" v-if="renderDialog">
        <div class="position-fixed d-flex flex-column bg-black" style="inset: 0!important; z-index: 99999;">
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-link text-white" @click="hideDialog()">
                    <i class="bi bi-x-lg"></i>
                </button>
            </div>

            <OCamera :dataObject="props.dataObject" @use-image="onUseImage"></OCamera>
        </div>
    </Teleport>
</template>

<script setup lang="ts">
    import { ref } from 'vue';

    import type DataObject from 'o365.modules.DataObject.ts';

    import OCamera from 'o365.vue.components.Camera.vue';

    /* -------------------- */
    /* ---- Interfaces ---- */
    /* -------------------- */
    export interface IProps {
        dataObject?: DataObject 
    }

    export interface IDefaultProps {}

    /* ---------------------- */
    /* ---- Status Flags ---- */
    /* ---------------------- */
    const renderDialog = ref<boolean>(false);

    /* --------------- */
    /* ---- Props ---- */
    /* --------------- */
    const props = withDefaults<IProps, keyof IProps, IDefaultProps>(defineProps<IProps>(), {
        startStreamOnMount: true
    });

    /* --------------- */
    /* ---- Emits ---- */
    /* --------------- */
    const emit = defineEmits<{
        (e: 'useImage', file: File): void
    }>();

    /* ------------------- */
    /* ---- Functions ---- */
    /* ------------------- */
    const showDialog = () => {
        const documentElement = document.documentElement;

        if (documentElement.requestFullscreen) {
            documentElement.requestFullscreen();
        } else if (documentElement.webkitRequestFullscreen) { /* Safari */
            documentElement.webkitRequestFullscreen();
        }

        renderDialog.value = true;
    };

    const hideDialog = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        }

        renderDialog.value = false;
    };

    const onUseImage = (file: File) => {
        emit('useImage', file);

        hideDialog();
    };
</script>